"use strict";

/* globals $ */

function ProductTileClass(element) {
    // prepare dom
    this.element = element;
    // add initialized class for passed element
    this.element.classList.add("product-tile-initialized");

    this.sizeButtons = this.element.querySelectorAll(".select-size") || [];
    this.productImage = this.element.querySelector(".image-container");
    this.productTitle = this.element.querySelector(".pdp-link a.link");
    this.productPrice = this.element.querySelector(".price");

    // prepare events
    /**
    * Gets the selected size from the product variation attributes
    * @param {Object} data - product data
    * @returns {Object} - the selected size object
    */
    this.getSelectedSize = function (data) {
        return (data?.product?.variationAttributes || [])
            .find(attribute => attribute.attributeId === "size")
            ?.values.find(value => value.selected) || {};
    };
    /**
    * Updates the product image with the selected size and url
    * @param {Object} selectedSize - the selected size object
    * @param {string} url - the url to the product page
    */
    this.updateProductImage = function (selectedSize, url, data) {
        let linkElement = this.productImage?.querySelector("a");
        let imageElement = linkElement?.querySelector("img");
        let imageData = selectedSize?.images?.medium?.[0] || null;

        if (imageElement && imageData) {
            imageElement.title = imageData.title || imageElement.title || "";
            imageElement.src = imageData.url || imageElement.src || "";
            imageElement.alt = imageData.alt || imageElement.alt || "";
        }

        if (linkElement) {
            linkElement.href = url || linkElement.href || "";
        }
        
        // Checks if any of the values in the variationAttributes array are selectable
        function isEmpty(value) {
            return value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0) || (typeof value === "object" && Object.keys(value).length === 0);
        }
        var productData = !isEmpty(data) && "product" in data && !isEmpty(data.product) ? data.product : null;
        var variationAttribute = !isEmpty(productData) && "variationAttributes" in productData && !isEmpty(productData.variationAttributes[0]) ? productData.variationAttributes[0] : null;
        var variationAttributeValues = !isEmpty(variationAttribute) && "values" in variationAttribute && !isEmpty(variationAttribute.values) ? variationAttribute.values : null;
        var isProductInStock = productData && variationAttribute && variationAttributeValues && variationAttributeValues.some(variant => variant && variant.selectable);

        // update the image container class
        if (!isProductInStock) {
            this.productImage.classList.toggle("out-of-stock", !selectedSize.selectable);
        }
    };
    /**
    * Updates the product title with the given data
    * @param {Object} data - the data object containing the product name
    * @param {string} url - the url of the product page
    */
    this.updateProductTitle = function (data, url) {
        if (this.productTitle) {
            this.productTitle.href = url || this.productTitle.href;
            this.productTitle.textContent = data?.product?.productName || this.productTitle.textContent;
        }
    };
    /**
    * Updates the product price on the page
    * @param {Object} data - the data object containing the product price info
    */
    this.updateProductPrice = function (data) {
        let updatePriceHtml = data?.product?.price?.html;
        if (updatePriceHtml) {
            let updatedPriceElement = document.createElement("div");
            updatedPriceElement.innerHTML = updatePriceHtml;
            let priceElement = updatedPriceElement.querySelector(".price");

            this.productPrice.innerHTML = priceElement.innerHTML;
        }
    };
    /**
    * Adds a class to the size buttons based on the selected size
    * @param {Object} selectedSize - the selected size
    */
    this.addSelectedClass = function (selectedSize) {
        this.sizeButtons.forEach(sizeButton => {
            const isSelected = selectedSize && selectedSize.value === sizeButton.dataset.attrValue;
            sizeButton.classList.toggle("selected", isSelected);
        });
    };
    /**
    * Handles the successful response from the getVariation request
    * @param {Object} data - the response data from the getVariation request
    */
    this.onGetVariationSuccess = function (url, data) {
        let selectedSize = this.getSelectedSize(data);
        this.updateProductImage(selectedSize, url, data);
        this.updateProductTitle(data, url);
        this.updateProductPrice(data);
        this.addSelectedClass(selectedSize);
    };
    /**
    * Retrieves the variation data from the given URL
    * @param {string} url - The URL to retrieve the variation data from
    * @param {string} pdpUrl - The URL to the product page
    */
    this.getVariationData = function (url, pdpUrl) {
        if (url) {
            $.ajax({
                url: url,
                method: "GET",
                success: this.onGetVariationSuccess.bind(this, pdpUrl)
            });
        }
    };

    // bind events to each size button
    this.sizeButtons.forEach(function (sizebutton) {
        let that = this;
        sizebutton.addEventListener("click", function (e) {
            e.preventDefault();

            // do not deselect the selected size
            if (sizebutton.classList.contains("selected")) {
                return;
            }

            // get the variation data
            // and update the product image, title and price
            // and add the "selected" class to the selected size button
            that.getVariationData(sizebutton.getAttribute("value"), sizebutton.dataset.pdpUrl);
        });
    }, this);
}

module.exports = ProductTileClass;
