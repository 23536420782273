"use strict";

/* globals $ */
var ProductTileClass = require("./ProductTileClass");
var cache = {};

/**
* Initializes the DOM elements
*/
function initializeDom() {
    cache.$document = $(document);
    cache.$productTiles = cache.$document.find(".product-tile, .product-tile-pd");
}

/**
* Initializes the events for the product tiles
*/
function initializeEvents() {
    cache.$productTiles
        .not(".product-tile-initialized")
        .toArray()
        .forEach(function (productTile) {
            new ProductTileClass(productTile);
        });
}

module.exports = {
    init: function () {
        setTimeout(function () {
            initializeDom();
            initializeEvents();
        }, 1000);

    }
};
